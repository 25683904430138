import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import css from './BlockGridCard.module.css';

import SystemPng from '../../../../assets/System.png';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;

  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;

  // Apply different styles based on block ID
  let blockClass = '';
  switch (blockId) {
    case 'grid-block-1':
      blockClass = css.gridBlock1;
      break;
    case 'grid-block-2':
      blockClass = css.gridBlock2;
      break;
    case 'grid-block-3':
      blockClass = css.gridBlock3;
      break;
    default:
      blockClass = '';
  }
  const classes = classNames(rootClassName || className, blockClass, css.wrapper);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <div className={classes}>
        <FieldMedia media={media} sizes={responsiveImageSizes} options={options} key={blockId} />

        {blockId === 'grid-block-1' ? (
          <img alt="systemImage" src={SystemPng} className={css.MediaImage} />
        ) : null}

        {hasTextComponentFields ? (
          <div className={classNames(textClassName, css.text)}>
            <Field data={title} options={options} />
            <Field data={text} options={options} />
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          </div>
        ) : null}
      </div>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
