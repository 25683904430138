import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFAQ.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faInbox,
  faLaptop,
  faShieldHalved,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faPenToSquare } from '@fortawesome/free-regular-svg-icons';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockFAQ = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    alignment,
  } = props;
  const classes = classNames(rootClassName, css.card || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const alignmentClasses = {
    left: css.alignLeft,
    center: css.alignCenter,
    right: css.alignRight,
  };

  const alignmentClass = alignmentClasses[alignment];

  //change fa icons based of the value of blockId

  const iconMapping = {
    'faq-card-1': faCreditCard,
    'faq-card-2': faLaptop,
    'faq-card-3': faStripe,
    'faq-card-4': faPenToSquare,
    'faq-card-5': faShieldHalved,
    'faq-card-6': faInbox,
    'faq-card-7': faTruck,
    'faq-card-8': faHeadset,
  };

  const icon = iconMapping[blockId] || faQuestionCircle; // Default icon if blockId is not found

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, alignmentClass, css.text)}>
          <div className={css.iconWrapper}>
            <FontAwesomeIcon icon={icon} className={css.icon} />
          </div>
          <Field data={title} options={options} className={css.title} />
          <Field data={text} options={options} className={css.answer} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFAQ.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockFAQ.propTypes = {
  blockId: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockFAQ;
